<template>
  <b-card
    title="Add Volunteer"
  >
    <loading
      :active="isLoading"
      loader="bars"
      color="#7367f0"
      :is-full-page="fullPage"
    />
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form
        ref="form"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Name"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <v-select
                  v-model="volunteer"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="volunteerOptions"
                  @search="onSearch"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Ministry"
              label-for="ministry"
            >
              <validation-provider
                #default="{ errors }"
                name="Ministry"
                rules="required"
              >
                <v-select
                  v-model="ministry"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="ministryOptions"
                  @input="onMinistryChanged"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Division"
              label-for="division"
            >
              <validation-provider
                #default="{ errors }"
                name="Division"
                rules="required"
              >
                <v-select
                  v-model="division"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="divisionOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Churches"
              label-for="churches"
            >
              <validation-provider
                #default="{ errors }"
                name="Churches"
                rules="required"
              >
                <v-select
                  v-model="churches"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="churchOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Position"
              label-for="position"
            >
              <validation-provider
                #default="{ errors }"
                name="Position"
                rules="required"
              >
                <v-select
                  v-model="position"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="positionOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Start date"
              label-for="start_date"
            >
              <validation-provider
                #default="{ errors }"
                name="Start date"
                rules="required"
              >
                <b-form-datepicker
                  v-model="start_date"
                  class="mb-1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Status"
              label-for="status"
            >
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <v-select
                  v-model="status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="statusOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button
              variant="primary"
              class="mr-1"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :to="{ name: 'volunteers' }"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _ from 'lodash'
// import 'vue-input-search/dist/vue-search.css'
// import VueSearch from 'vue-input-search/dist/vue-search.common'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormDatepicker,
    vSelect,
    Loading,
  },
  directives: {
      Ripple,
  },
  data() {
    return {
      isLoading: false,
      fullPage: false,
      volunteer: [],
      volunteerOptions: [],
      ministry: [],
      ministryOptions: [],
      division: [],
      divisionOptions: [],
      churches: [],
      churchOptions: [],
      start_date: '',
      status: [],
      statusOptions: [
        {
            id: 0,
            name: 'Need rereg',
        },
        {
            id: 1,
            name: 'Need verification for reregistration',
        },
        {
            id: 2,
            name: 'Active',
        },
      ],
      position: [],
      positionOptions: [
        {
            name: 'LEADER',
        },
        {
            name: 'MEMBER',
        },
      ],
      required,
    }
  },
  mounted() {
    // Get ministries
    this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/ministries`)
        .then(ministries => {
            _.forEach(ministries.data, ministry => {
                this.ministryOptions.push({ id: ministry.id, name: ministry.name })
            })
        })

    // Churches
    this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/churches`)
        .then(result => {
            _.forEach(result.data, church => {
                this.churchOptions.push({ id: church.couchdbId, name: church.name })
            })
        })
  },
  methods: {
    onSearch(search, loading) {
        if (search.length) {
            loading(true)
            this.search(loading, search, this)
        }
    },
    search: _.debounce((loading, search, vm) => {
        vm.$http.get(
            `${process.env.VUE_APP_API_BASE_URL}/api/peoples?q=${search}`,
        ).then(res => {
            vm.volunteerOptions = res.data
            loading(false)
        })
    }, 350),
    onMinistryChanged(value) {
        // Get division
        this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/divisions/ministry/${value.id}`)
            .then(divisions => {
                this.divisionOptions = []
                _.forEach(divisions.data, result => {
                    this.divisionOptions.push({ id: result.id, name: result.name })
                })
            })
    },
    validationForm() {
      // Check for cookies before submit
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // Show loading
          this.isLoading = true
          
          // Set the volunteer data
          const data = {
            personId: this.volunteer.id,
            divisionId: this.division.id,
            churchId: this.churches.id,
            position: this.position.name,
            startDate: Number(this.start_date.split('-')[2]),
            startMonth: Number(this.start_date.split('-')[1]),
            startYear: this.start_date.split('-')[0],
            ministryId: this.ministry.id,
            status: this.status.id,
          }

          // Check if user has access
          if (!this.$can('add', 'volunteers')) {
            return this.$router.replace({ name: 'misc-not-authorized' })
          }

          // Check if the user and the ministry has been inputted before
          // this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/volunteer/check/${this.volunteer.id}/${this.ministry.id}`)
          //   .then(result => {
          //     console.log(result)
          //   })

          // Save role
          this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/volunteer`, data)
            .then(result => {
              this.isLoading = false

              // Redirect to role lists
              this.$router.replace({ name: 'volunteers' })
                .then(() => {
                  let title = 'Success'
                  let icon = 'CheckIcon'
                  let variant = 'success'
                  let text = 'Data has been saved successfully.'
                  // if error
                  if (result.data.error) {
                    title = 'Error'
                    icon = 'XIcon'
                    variant = 'danger'
                    text = 'This data has already existed.'
                  }

                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title,
                      icon,
                      variant,
                      text,
                    },
                  })
                })
            })
        }
      })
    },
  },
}
</script>